import React from "react";
import { LinkOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Card, SafeArea, Space } from "antd-mobile";
import { Grid } from "antd/lib";
import CustomInputMobile from "../../Global/CustomInput/Mobile";
import CustomButtonMobile from "../../Global/CustomButton/Mobile";
import MultilinesInputMobile from "../../Global/MultilinesInput/Mobile";
import "../../../App.css"
import CustomButton from "../../Global/CustomButton/Desktop";

interface ProductDetailProps {
  onClick: () => void;
  onbackClick: () => void;
  formdata: ProductFormData;
  setformdata: React.Dispatch<React.SetStateAction<ProductFormData>>;
}

interface ProductFormData {
  producturl: string;
  productname: string;
  productdescribtion: string;
  price: string | number;
  productimages: Array<{
    uid: string;
    file: File;
    preview: string;
  }>;
}

const ProductDetailMobile: React.FC<ProductDetailProps> = ({
  onClick,
  onbackClick,
  formdata,
  setformdata,
}) => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(e.target.files || []).map((file) => ({
      uid: `${Date.now()}-${Math.random()}`,
      file,
      preview: URL.createObjectURL(file),
    }));

    setformdata((prevData) => {
      const currentImages = Array.isArray(prevData.productimages)
        ? prevData.productimages
        : [];

      if (currentImages.length + newFiles.length > 5) {
        alert("You can only select up to 5 images.");
        return prevData;
      }

      const updatedImages = [...currentImages, ...newFiles];
      return {
        ...prevData,
        productimages: updatedImages,
      };
    });
  };

  const removeImage = (uid: string) => {
    setformdata((prevData) => {
      const updatedImages = prevData.productimages.filter(
        (image) => image.uid !== uid
      );
      return {
        ...prevData,
        productimages: updatedImages,
      };
    });
  };

  return (
    <div>
      <Card
        style={{
          borderRadius: "18px",
          boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
          paddingBottom: 15,
        }}
      >
        <h2 style={{ textAlign: "center", fontWeight: "700", fontFamily: "Inter", }}>Product Details</h2>
        <h4 style={{ marginBottom: "3px", fontSize: "12px", fontWeight: "700", fontFamily: "Inter", }}>Product URL</h4>

        <CustomInputMobile
          placeholder="Paste product URL here"
          value={formdata?.producturl}
          onChange={(val) => {
            setformdata({
              ...formdata,
              producturl: val,
            });
          }}
          prefix={<LinkOutlined style={{ color: "#ccc" }} />}
        />

        <div
          style={{ display: "flex", alignItems: "center", margin: "20px 0" }}
        >
          <div
            style={{ flex: 1, height: "1px", backgroundColor: "#e9ecef" }}
          ></div>
          <div
            style={{
              margin: "0 8px",
              whiteSpace: "nowrap",
              color: "#e9ecef",
              fontWeight: "400",
              fontFamily: "Inter",
            }}
          >
            OR
          </div>
          <div
            style={{ flex: 1, height: "1px", backgroundColor: "#e9ecef" }}
          ></div>
        </div>

        <h4 style={{ marginBottom: "3px", fontSize: "12px", fontWeight: "700", fontFamily: "Inter", }}>Product Title</h4>

        <CustomInputMobile
          placeholder="Product Name"
          value={formdata.productname}
          onChange={(val) =>
            setformdata({
              ...formdata,
              productname: val,
            })
          }
        />

        <h4 style={{ marginBottom: "3px", fontSize: "12px", fontWeight: "700", fontFamily: "Inter", }}>Description</h4>
        <MultilinesInputMobile
          placeholder="Product Description"
          value={formdata.productdescribtion}
          onChange={(val) =>
            setformdata({
              ...formdata,
              productdescribtion: val,
            })
          }
        />

        <h4 style={{ marginBottom: "3px", fontSize: "12px", fontWeight: "700", fontFamily: "Inter", }}>Product Images (Optional)</h4>
        <div
          style={{
            height: "100px",
            border: "1px solid #ccc",
            backgroundColor: "#fff",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <UploadOutlined style={{ color: "#8E8E93", fontSize: "18px" }} />
          <span style={{ color: "#8E8E93", fontSize: "12px", fontWeight: "400", fontFamily: "Inter", }}>Upload</span>
          <div
            style={{
              border: "0px",
              backgroundColor: "transparent",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 0,
            }}
          >
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              multiple
              disabled={formdata.productimages?.length === 5}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                opacity: 0,
                cursor:
                  formdata?.productimages?.length === 5
                    ? "not-allowed"
                    : "pointer",
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "8px",
            alignItems: "center",
            marginTop: 5,
          }}
        >
          {formdata.productimages.map((fileObj) => (
            <div
              key={fileObj.uid}
              style={{
                position: "relative",
                width: "80px",
                height: "80px",
                border: "1px solid #d9d9d9",
                borderRadius: "4px",
                overflow: "hidden",
              }}
            >
              <img
                src={fileObj.preview}
                alt="Uploaded"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
              <button
                onClick={() => removeImage(fileObj.uid)}
                style={{
                  position: "absolute",
                  top: "4px",
                  right: "2px",
                  background: "#9fe870",
                  color: "#0b5727",
                  border: "none",
                  borderRadius: "50%",
                  cursor: "pointer",
                  width: "16px",
                  height: "16px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "12px",
                  lineHeight: "1",
                }}
              >
                ×
              </button>
            </div>
          ))}
        </div>

        <h4 style={{ marginBottom: "3px", fontSize: "12px", fontWeight: "700", fontFamily: "Inter", }}>Price (LKR)</h4>

        <CustomInputMobile
          placeholder="(LKR). 0.0"
          value={formdata.price?.toString() || ""}
          onChange={(val) =>
            setformdata({
              ...formdata,
              price: val,
            })
          }
          type="number"
        />

        <Space
          // direction="horizontal"
          style={{
            display: "flex",
            flexDirection: "row",
            // width: "100%", 
            justifyContent: "space-between",
          }}
        >
          <CustomButtonMobile
            fill="outline"
            label="Previous"
            onClick={onbackClick}
            style={{
              width: screens.xs ? "150px" : screens.md ? "47vw" : "45vw",
            }}
          />
          <CustomButtonMobile
            disabled={
              !formdata.price || (!formdata.productname && !formdata.producturl)
            }
            label="Next"
            onClick={() => {
              localStorage.setItem(
                "productdetail",
                JSON.stringify({
                  price: formdata.price,
                  productname: formdata.productname,
                  producturl: formdata.producturl,
                  productimages: formdata.productimages,
                  productdescribtion: formdata.productdescribtion,
                })
              );
              onClick();
            }}
            style={{
              width: screens.xs ? "150px" : screens.md ? "47vw" : "45vw",
            }}
          />
        </Space>
      </Card >
    </div >
  );
};

export default ProductDetailMobile;
