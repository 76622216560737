import React, { useState } from 'react'
import Header from '../../Components/Global/Header'
import { Layout } from 'antd'
import { Content } from 'antd/es/layout/layout'
import Responsive from '../../Hooks/Responsive'
import SellerJoinTransactionMobile from '../../Components/Global/SellerStep/Mobile'
import { useNavigate } from 'react-router-dom'
import BottomBar from '../../Components/Global/BottomBar'
import product_one from "../../assets/Images/product_one.png";
import product_two from "../../assets/Images/product_two.png";
import CameraCustomMobile from '../../Components/Global/CameraCustom/Mobile'

const SellerJoinTransaction: React.FC = () => {
    const deviceType = Responsive();
    const navigate = useNavigate();

    const [data, setData] = useState<{
        status: '' | 'pending' | 'success' | 'error';
        message: string;
    }>({
        status: '',
        message: ''
    });
    const [openFrontCamera, setOpenFrontCamera] = useState(false);
    const [openBackCamera, setOpenBackCamera] = useState(false);
    const [formdata, setFormData] = useState({
        actionRequired: false,
        name: "",
        accountNumber: "",
        bankName: "",
        branchName: "",
        identityType: "",
        frontImage: "",
        backImage: "",
    });

    const steps = [
        { id: 1, label: "Funds in TrustPay hold" },
        { id: 2, label: "Buyer Confirms" },
        { id: 3, label: "Funds release" },
    ];

    const productData = {
        title: "Chanel Trainers UK size 5",
        price: "LKR. 170,000.00",
        description: "Iconic authentic Chanel trainers sneakers size UK 5. Comes with the original box",
        images: [
            product_one,
            product_two,
            product_one
        ]
    };

    const handleSubmit = () => {
        setData({
            status: 'pending',
            message: 'Your information has been submitted successfully'
        })
    }

    return (
        <div style={{ position: "relative", display: "flex", flexDirection: "column", minHeight: "100vh" }}>

            {openFrontCamera || openBackCamera ? (
                <>
                    {openFrontCamera && <CameraCustomMobile
                        setFormData={setFormData}
                        formData={formdata}
                        setOpenFrontCamera={setOpenFrontCamera}
                        setOpenBackCamera={setOpenBackCamera}
                        handleCloseCamera={() => setOpenFrontCamera(false)}
                        openFrontCamera={openFrontCamera}
                        openBackCamera={openBackCamera}
                    />
                    }
                    {openBackCamera && <CameraCustomMobile
                        setFormData={setFormData}
                        formData={formdata}
                        handleCloseCamera={() => setOpenBackCamera(false)}
                        setOpenBackCamera={setOpenBackCamera}
                        setOpenFrontCamera={setOpenFrontCamera}
                        openFrontCamera={openFrontCamera}
                        openBackCamera={openBackCamera}
                    />
                    }
                </>

            )
                : (
                    <>
                        <Header />

                        <Layout
                            style={{
                                flex: 1,
                                overflowX: "hidden",
                                backgroundColor: "#F5F5F5",
                            }}
                        >


                            {/* Scrollable Content */}
                            <Content style={{ overflowY: "auto", padding: "15px 15px 85px 15px", flexGrow: 1 }}>
                                {deviceType === "desktop" ? (
                                    <SellerJoinTransactionMobile
                                        currentStep={1}
                                        handleSubmit={handleSubmit}
                                        completedSteps={[0]}
                                        formdata={formdata}
                                        setFormData={setFormData}
                                        steps={steps}
                                        productData={productData}
                                        handleOpenFrontCamera={() => setOpenFrontCamera(true)}
                                        handleOpenBackCamera={() => setOpenBackCamera(true)}
                                        data={data}
                                    />
                                ) : (
                                    <SellerJoinTransactionMobile
                                        currentStep={0}
                                        handleSubmit={handleSubmit}
                                        completedSteps={[0]}
                                        formdata={formdata}
                                        setFormData={setFormData}
                                        steps={steps}
                                        productData={productData}
                                        handleOpenFrontCamera={() => setOpenFrontCamera(true)}
                                        handleOpenBackCamera={() => setOpenBackCamera(true)}
                                        data={data}
                                    />
                                )}
                            </Content>


                            <BottomBar
                                onStartTransactionClick={() => { }}
                                onMyTransactionsClick={() => { }}
                                onMyListingsClick={() => { }}
                                onProfileClick={() => { }}
                                activeTab="myTransactions"
                            />
                        </Layout>
                    </>
                )

            }
        </div >
    )
}

export default SellerJoinTransaction