import React from 'react';

interface NotificationBoxProps {
    title: string;
    icon?: React.ReactNode;
    borderColor?: string;
    backgroundColor?: string;
    styletitle?: React.CSSProperties;
    style?: React.CSSProperties;
    padding?: string;
}

const NotificationBoxMobile: React.FC<NotificationBoxProps> = ({
    title,
    icon,
    borderColor = '#FFB74D',
    backgroundColor = '#FFF3E0',
    style,
    styletitle,
    padding
}) => {
    return (
        <div style={{
            ...style,
            display: 'flex',
            alignItems: 'ceter',
            justifyContent: 'center',
            padding: padding ? padding : '10px 10px',
            borderRadius: '5px',
            border: `1px solid ${borderColor}`,
            backgroundColor: backgroundColor,
            marginTop: '13px'
        }}>
            {icon}
            <span style={{
                ...styletitle,
                flex: 1,
                color: '#000000',
                fontSize: '10px',
                fontFamily: 'Inter',
                fontWeight: '400',
                lineHeight: '14px'
            }}>
                {title}
            </span>
        </div>
    );
};

export default NotificationBoxMobile;