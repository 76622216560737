import React, { FC } from "react";
import { Input } from "antd";

interface TextAreaProps {
  placeholder?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  autoSize?: { minRows: number; maxRows: number };
  style?: React.CSSProperties;
}

const MultilinesInput: FC<TextAreaProps> = ({
  placeholder = "Enter text...",
  value,
  onChange,
  autoSize = { minRows: 4, maxRows: 4 },
  style,
}) => {
  return (
    <Input.TextArea
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      autoSize={autoSize}
      style={{
        marginBottom: "16px",
        borderRadius: "8px",
        fontFamily: "Inter",
        fontWeight: 400,
        ...style,
      }}
      onFocus={(e) => (e.target.style.borderColor = "lightgreen")}
      onBlur={(e) => (e.target.style.borderColor = "#ccc")}
    />
  );
};

export default MultilinesInput;
