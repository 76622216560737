import React, { FC, CSSProperties } from "react";
import { Input } from "antd-mobile";

interface CustomInputMobileProps {
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  prefix?: React.ReactNode;
  type?: string;
  style?: CSSProperties;
  props?: React.HTMLAttributes<HTMLInputElement>;
  defaultValue?: string;
  disabled?: boolean;
}

const CustomInputMobile: FC<CustomInputMobileProps> = ({
  placeholder,
  value,
  onChange,
  prefix,
  type = "text",
  style,
  defaultValue,
  disabled,
  props,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        borderRadius: "8px",
        border: "1px solid #ccc",
        backgroundColor: "#fff",
        height: "33px",
        padding: "0 10px",
        ...style,
      }}
    >
      {prefix && (
        <span
          style={{
            marginRight: "8px",
            fontSize: "15px",
            color: "#8E8E93",
          }}
        >
          {prefix}
        </span>
      )}
      <Input
        disabled={disabled}
        className="custom-input"
        {...props}
        type={type}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        onChange={(val) => onChange(val)}
        style={{
          flex: 1,
          appearance: type === "number" ? "textfield" : "none",
          padding: "0",
          fontFamily: "Inter",
          fontWeight: 400,
        }}
        onFocus={(e) => (e.target.style.borderColor = "lightgreen")}
        onBlur={(e) => (e.target.style.borderColor = "#ccc")}
      />
    </div>
  );
};

export default CustomInputMobile;
