import React from "react";
import { ShoppingCartOutlined, ShopOutlined, LinkOutlined } from "@ant-design/icons";
import SelectableButton from "../../Global/SelectableButton/Desktop";
import CustomButton from "../../Global/CustomButton/Mobile";
import { Button, Card, Space } from "antd-mobile";
import color from "../../../Utils/color";
import CustomInputMobile from "../../Global/CustomInput/Mobile";
import CustomButtonMobile from "../../Global/CustomButton/Mobile";
import IconButtonMobile from "../../Global/IconButton/Mobile";
import toast from "antd-mobile/es/components/toast";
import ShareOnSocial from "react-share-on-social";

interface TransactionLinkProps { }

const TransactionLinkMobile: React.FC<TransactionLinkProps> = () => {


    // Add transaction link constant
    const transactionLink = "https://trustpay.lk/transaction/9hfdu0jik3";

    // Add copy handler
    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(transactionLink);
            toast.show('Link copied to clipboard!');
        } catch (error) {
            console.error('Error copying text:', error);
            // Fallback method for copying
            const textArea = document.createElement('textarea');
            textArea.value = transactionLink;
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand('copy');
                toast.show('Link copied to clipboard!');
            } catch (err) {
                toast.show('Failed to copy link');
            }
            document.body.removeChild(textArea);
        }
    };


    return (
        <div>
            <h2 style={{ fontSize: '18px', textAlign: "center", marginBottom: "40px", fontWeight: "700", fontFamily: "Inter", }}>Transaction Created Successfully</h2>
            <p style={{ fontSize: '12px', marginBottom: "40px", fontWeight: "500", fontFamily: "Inter", }}>Now you can share this link with the seller.</p>

            <CustomInputMobile
                placeholder=""
                value={transactionLink}
                disabled={true}
                onChange={() => { }}
                style={{
                    fontSize: '12px',
                    color: "#000000",
                    padding: "0 20px",
                    fontWeight: "400",
                    fontFamily: "Inter",
                }}
            />

            <Space
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    marginTop: "20%"
                }}
            >
                <ShareOnSocial
                    shareTo={['facebook', 'whatsapp', 'twitter', 'email']}
                    textToShare="Share Transaction Link"
                    link={transactionLink}
                    linkFavicon="https://api.keyp.co.za/storage/favicon.ico"
                    noReferer
                    sx={{ cursor: 'pointer' }} // This makes the entire ShareOnSocial component clickable with a hand cursor
                >
                    <IconButtonMobile
                        className="custom-button"
                        icon={<LinkOutlined style={{ color: "#163300", fontSize: "14px" }} />}
                        label="Share Link"
                    />
                </ShareOnSocial>
                <CustomButtonMobile
                    fill="outline"
                    label="Copy Link"
                    onClick={handleCopy}
                    style={{
                        width: "100%",
                        fontSize: "12px",
                    }}
                />
            </Space>

        </div>
    );
};

export default TransactionLinkMobile;
