import { Grid } from "antd";
import { useState, useEffect } from "react";

const { useBreakpoint } = Grid;

type DeviceType = "desktop" | "mobile";

const Responsive = (): DeviceType => {
  const screens = useBreakpoint();

  const [deviceType, setDeviceType] = useState<DeviceType>("desktop");

  useEffect(() => {
    if (screens.md || screens.lg || screens.xl) {
      setDeviceType("desktop");
    } else if (screens.xs || screens.sm) {
      setDeviceType("mobile");
    }
  }, [screens]);

  return deviceType;
};

export default Responsive;
