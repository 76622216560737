import React, { useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import CreateTransaction from "./Pages/StepScreen";
import TransactionLinkScreen from "./Pages/StepScreen/TransactionLinkScreen";
import SellerStepScreen from "./Pages/SellerStepScreen";
import TransactionForm from "./Pages/SellerStepScreen/TransactionForm";
import SellerJoinTransaction from "./Pages/SellerStepScreen/SellerJoinTransaction";

const App: React.FC = () => {
  const handleButtonClick = () => {
    console.log("Button clicked");
  };
  const [selected, setSelected] = useState<boolean>(false);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/create_transaction" />} />
        <Route path="/create_transaction" element={<CreateTransaction />} />
        <Route path="/create_transaction/transaction_link" element={<TransactionLinkScreen />} />
        <Route path="/request_join_transaction" element={<SellerStepScreen />} />
        <Route path="request_join_transaction/join_transaction" element={<TransactionForm />} />
        <Route path="/my_transaction" element={<SellerJoinTransaction />} />
      </Routes>
    </Router>
  );
};

export default App;
