import React, { useState } from 'react';

import product_one from "../../../assets/Images/product_one.png";
import { Checkbox, Space } from 'antd-mobile';
import IconButtonMobile from '../../Global/IconButton/Mobile';
import CustomButtonMobile from '../../Global/CustomButton/Mobile';
import { LinkOutlined } from '@ant-design/icons';


interface RequestTransactionMobileProps {
    onclick: () => void;
    formData: any;
    setFormData: any;
    handleTermsChange: any;
}

const RequestTransactionMobile: React.FC<RequestTransactionMobileProps> = ({
    onclick,
    formData,
    setFormData,
    handleTermsChange
}) => {

    return (
        <div style={{ marginTop: "10%" }}>
            <h2 style={{ fontSize: "17px", fontWeight: "700", fontFamily: "Inter", color: "#000000", textAlign: "center", margin: "0 20px" }}>
                You’ve been invited to join a TrustPay transaction
            </h2>

            <div style={{ display: "flex", flexDirection: "row", marginTop: "20px", marginBottom: 10 }}>
                <p style={{ fontSize: "12px", fontWeight: "600", fontFamily: "Inter", color: "#000000", width: "95px", margin: 0 }}>Buyer Name :</p>
                <p style={{ fontSize: "12px", color: "#000000", flex: 1, margin: 0, fontWeight: "400", fontFamily: "Inter", }}>Lelani Dissanayake</p>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
                <p style={{ fontSize: "12px", fontWeight: "600", fontFamily: "Inter", color: "#000000", width: "95px", margin: 0 }}>Product Name :</p>
                <p style={{ fontSize: "12px", color: "#000000", flex: 1, margin: 0, fontWeight: "400", fontFamily: "Inter", }}>Chanel Trainers UK size 5</p>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
                <p style={{ fontSize: "12px", fontWeight: "600", fontFamily: "Inter", color: "#000000", width: "95px", margin: 0 }}>Description :</p>
                <p style={{ fontSize: "12px", color: "#000000", flex: 1, margin: 0, fontWeight: "400", fontFamily: "Inter", }}>Iconic authentic Chanel trainers sneakers size UK 5. Comes with the original box </p>
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
                <p style={{ fontSize: "12px", fontWeight: "600", fontFamily: "Inter", color: "#000000", margin: '0 10px 0 0' }}>Price :</p>
                <p style={{ fontSize: "12px", fontWeight: "600", fontFamily: "Inter", color: "#000000", flex: 1, margin: 0 }}>LKR. 170,000.00</p>
            </div>

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", marginTop: "50px", marginBottom: "50px" }}>
                {[1, 2, 3].map(() => {
                    return (
                        <img src={product_one} alt="" style={{ width: "27%", height: "auto", marginRight: "10px" }} />
                    )
                })}
            </div>

            <p style={{ fontSize: "12px", color: "#000000", flex: 1, margin: 0, fontWeight: "400", fontFamily: "Inter", }}>Review the product description and the price provided by the buyer before joining the transaction.</p>

            <div style={{ marginTop: "40px", marginBottom: "20px", display: "flex", alignItems: "flex-start" }}>
                <Checkbox
                    checked={formData.seller_invite_terms}
                    onChange={handleTermsChange}
                    style={{ marginTop: "-1px" }}
                />
                <span style={{ fontSize: "13px", marginLeft: "10px", fontWeight: "400", fontFamily: "Inter", }}>
                    I agree with the{" "}
                    <a href="#" style={{ color: "#1890ff", textDecorationLine: "underline", fontWeight: "500", fontFamily: "Inter", }}>
                        Terms and Conditions
                    </a>{" "}
                    and the{" "}
                    <a href="#" style={{ color: "#1890ff", textDecorationLine: "underline", fontWeight: "500", fontFamily: "Inter", }}>
                        Privacy Policy
                    </a>
                </span>
            </div>

            <Space
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    marginTop: "10%"
                }}
            >
                <CustomButtonMobile
                    className="custom-button"
                    label="Confirm & Join Transaction"
                    onClick={onclick}
                    disabled={!formData.seller_invite_terms}
                    style={{
                        width: "100%",
                        fontSize: "12px",
                    }}
                />
                <CustomButtonMobile
                    fill="outline"
                    label="Request Changes"
                    // onClick={onbackClick}
                    onClick={() => { }}
                    style={{
                        width: "100%",
                        fontSize: "12px",
                    }}
                />
            </Space>
            <span style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "12px", marginTop: "30px" }}>
                <a href="#" style={{ color: "#000000", textDecorationLine: "underline", fontWeight: "400", fontFamily: "Inter", }}>
                    Need Help? Contact Support
                </a>
            </span>
        </div >
    )
}

export default RequestTransactionMobile