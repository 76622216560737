import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import OTPInput from "react-otp-input";
import { Card, Checkbox, Input, Space } from "antd-mobile";
import CustomInputMobile from "../../Global/CustomInput/Mobile";
import CustomButtonMobile from "../../Global/CustomButton/Mobile";
import MultilinesInputMobile from "../../Global/MultilinesInput/Mobile";
import { motion } from "framer-motion";

interface VerificationMobileProps {
  onbackClick: () => void;
  verificationformdata: {
    mobilenumber?: string;
    otp?: string;
    name?: string;
    address?: string;
    email?: string;
    termsAccepted?: boolean;
  };
  setverificationformdata: React.Dispatch<
    React.SetStateAction<{
      mobilenumber?: string;
      otp?: string;
      name?: string;
      address?: string;
      email?: string;
      termsAccepted?: boolean;
    }>
  >;
}

const VerificationMobile: React.FC<VerificationMobileProps> = ({
  onbackClick,
  verificationformdata,
  setverificationformdata,
}) => {
  const navigate = useNavigate();
  const [otpshow, setotpshow] = useState<{ otp: boolean; detail: boolean }>({
    otp: false,
    detail: false,
  });

  const otpInputStyle: React.CSSProperties = {
    width: "60%",
    height: "30px",
    textAlign: "center",
    fontSize: "18px",
    borderRadius: "4px",
    boxSizing: "border-box",
    padding: "6px",
    borderWidth: "1.5px",
    borderStyle: "solid",
  };


  const handleTermsChange = (checked: boolean) => {
    setverificationformdata({
      ...verificationformdata,
      termsAccepted: checked,
    });
  };

  return (
    <Card
      style={{
        borderRadius: "18px",
        boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
      }}
    >
      <h2 style={{ textAlign: "center", marginBottom: "40px", fontWeight: "700", fontFamily: "Inter", }}>Verification</h2>
      <h4 style={{ marginBottom: "3px", fontSize: "12px", fontWeight: "700", fontFamily: "Inter", }}>Mobile Number</h4>

      <Space style={{
        height: "35px",
        width: "100%",
        borderRadius: "8px",
        border: "1px solid #ccc",
        display: "flex",
        alignItems: "center",
        padding: "0",
        justifyContent: "space-between",
      }}>
        <CustomInputMobile
          placeholder="Enter your mobile number"
          type="number"
          value={verificationformdata?.mobilenumber || ""}
          onChange={(e: string) => {
            setverificationformdata({
              ...verificationformdata,
              mobilenumber: e,
            });
          }}
          style={{
            flex: 1,
            border: "none",
            borderBottomRightRadius: "0px",
            borderTopRightRadius: "0px",
          }}
        />
        <CustomButtonMobile
          disabled={!!verificationformdata?.otp}
          label="Send OTP"
          onClick={() => {
            setotpshow({
              ...otpshow,
              otp: true,
            });
          }}
          style={{
            marginTop: 0,
            height: 35,
            border: "none",
            borderRadius: "5px",
            borderTopLeftRadius: "0px",
            borderBottomLeftRadius: "0px",
            fontSize: "12px",
          }}
        />
      </Space>

      {otpshow.otp && verificationformdata?.mobilenumber && (
        <motion.div
          initial={{ opacity: 0, y: 15 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div>
            <h4 style={{ marginBottom: "3px", fontSize: "12px", fontWeight: "700", fontFamily: "Inter", }}>Enter OTP</h4>
            <OTPInput
              value={verificationformdata?.otp || ""}
              onChange={(otp: string) => {
                setverificationformdata({ ...verificationformdata, otp });
              }}
              numInputs={6}
              renderSeparator={<span>-</span>}
              containerStyle={{ marginLeft: -5 }}
              renderInput={(props) => (
                <input
                  {...props}
                  type="number"
                  maxLength={1}
                  style={{
                    width: "40px",
                    height: "40px",
                    textAlign: "center",
                    margin: "0 5px 0 5px",
                    fontSize: "12px",
                    borderRadius: "8px",
                    border: "1px solid #ccc",
                    backgroundColor: props.value ? "#ECEEEA" : "white"
                  }}
                  onKeyDown={(e) => {
                    if (!/[0-9]/.test(e.key) && e.key !== "Backspace") {
                      e.preventDefault();
                    }
                  }}
                />
              )}
            />

            <div style={{ fontSize: "12px", marginTop: "10px", fontWeight: "400", fontFamily: "Inter", }}>
              Didn’t receive the code?{" "}
              <a
                style={{
                  color: "#000",
                  textDecorationLine: "underline",
                  fontWeight: "600",
                  fontFamily: "Inter",
                }}
              >
                Resend OTP
              </a>
            </div>
          </div>
        </motion.div>
      )
      }

      {
        verificationformdata?.otp && (
          <motion.div
            initial={{ opacity: 0, y: 15 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <div>
              <h4 style={{ marginBottom: "3px", fontSize: "12px", fontWeight: "700", fontFamily: "Inter", }}>Name</h4>
              <CustomInputMobile
                placeholder="Your Full Name"
                value={verificationformdata?.name || ""}
                onChange={(e: string) => {
                  setverificationformdata({
                    ...verificationformdata,
                    name: e,
                  });
                }}
              />
              <h4 style={{ marginBottom: "3px", fontSize: "12px", fontWeight: "700", fontFamily: "Inter", }}>Delivery Address</h4>
              <MultilinesInputMobile
                placeholder="Your Delivery Address"
                value={verificationformdata?.address || ""}
                onChange={(e: string) => {
                  setverificationformdata({
                    ...verificationformdata,
                    address: e,
                  });
                }}
              />
              <h4 style={{ marginBottom: "3px", fontSize: "12px", fontWeight: "700", fontFamily: "Inter", }}>Email</h4>
              <CustomInputMobile
                placeholder="Your email"
                value={verificationformdata?.email || ""}
                onChange={(e: string) => {
                  setverificationformdata({
                    ...verificationformdata,
                    email: e,
                  });
                }}
              />

              <div style={{ marginTop: "20px", marginBottom: "20px", display: "flex", alignItems: "flex-start" }}>
                <Checkbox
                  checked={verificationformdata.termsAccepted}
                  onChange={handleTermsChange}
                  style={{ marginTop: "-3px" }}

                />
                <span style={{ fontSize: "13px", marginLeft: "5px", fontWeight: "400", fontFamily: "Inter", }}>
                  I agree with the{" "}
                  <a href="#" style={{ color: "#1890ff", textDecorationLine: "underline", fontWeight: "500", fontFamily: "Inter", }}>
                    Terms and Conditions
                  </a>{" "}
                  and the{" "}
                  <a href="#" style={{ color: "#1890ff", textDecorationLine: "underline", fontWeight: "500", fontFamily: "Inter", }}>
                    Privacy Policy
                  </a>
                </span>
              </div>

            </div>
          </motion.div>
        )
      }

      <Space
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <CustomButtonMobile
          fill="outline"
          label="Previous"
          onClick={onbackClick}
          style={{
            fontSize: "12px",
            width: "150px",
          }}
        />
        <CustomButtonMobile
          disabled={
            !verificationformdata?.name ||
            !verificationformdata?.email ||
            !verificationformdata?.address ||
            !verificationformdata?.termsAccepted
          }
          label="Create Transaction"
          onClick={() => {
            localStorage.setItem(
              "verification",
              JSON.stringify({
                mobilenumber: verificationformdata?.mobilenumber,
                otp: verificationformdata?.otp,
                name: verificationformdata?.name,
                address: verificationformdata?.address,
                email: verificationformdata?.email,
                termsAccepted: verificationformdata?.termsAccepted,
              })
            );
            navigate("/create_transaction/transaction_link");
          }}
          style={{
            fontSize: "12px",
            width: "150px",
          }}
        />
      </Space>
    </Card >
  );
};

export default VerificationMobile;
