import React, { useEffect, useRef, useState } from "react";
import { Steps, Layout, ConfigProvider, Grid } from "antd";

import Responsive from "../../Hooks/Responsive";
import TransactionType from "../../Components/StepsScreenComponents/TransactionType/Desktop";
import TransactionTypeMobile from "../../Components/StepsScreenComponents/TransactionType/Mobile";
import ProductDetail from "../../Components/StepsScreenComponents/ProductDetail/Desktop";
import ProductDetailMobile from "../../Components/StepsScreenComponents/ProductDetail/Mobile";
import Verification from "../../Components/StepsScreenComponents/Verification/Desktop";
import VerificationMobile from "../../Components/StepsScreenComponents/Verification/Mobile";
import Header from "../../Components/Global/Header";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";

const { Step } = Steps;
const { Content } = Layout;
const { useBreakpoint } = Grid;

interface ProductFormData {
  producturl: string;
  productname: string;
  productdescribtion: string;
  price: string | number;
  productimages: Array<{
    uid: string;
    file: File;
    preview: string;
  }>;
}

interface VerificationFormData {
  mobilenumber?: string;
  otp?: string;
  name?: string;
  address?: string;
  email?: string;
}

const CreateTransaction: React.FC = () => {
  const navigate = useNavigate();
  const [selectedRole, setSelectedRole] = useState<string | null>(null);
  const [selectedPaymentType, setSelectedPaymentType] = useState<string | null>(null);
  const deviceType = Responsive();
  const screens = useBreakpoint();
  const [formdata, setformdata] = useState<ProductFormData>({
    producturl: "",
    productname: "",
    productdescribtion: "",
    price: "",
    productimages: [],
  });

  const [verificationFormData, setVerificationFormData] =
    useState<VerificationFormData>({
      mobilenumber: "",
      otp: "",
      address: "",
      email: "",
      name: "",
    });

  const prevDimensions = useRef({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("verification");
      localStorage.removeItem("productdetail");
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);


  useEffect(() => {
    const handleResize = () => {
      const { width: prevWidth, height: prevHeight } = prevDimensions.current;

      if (
        window.innerWidth !== prevWidth ||
        window.innerHeight !== prevHeight
      ) {
        prevDimensions.current = {
          width: window.innerWidth,
          height: window.innerHeight,
        };
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const verification = localStorage.getItem("verification");
    if (verification) {
      const parsedData = JSON.parse(verification);
      console.log("formdata of verification =====", parsedData);
      setVerificationFormData({
        ...verificationFormData,
        mobilenumber: parsedData?.mobilenumber,
        otp: parsedData?.otp,
        name: parsedData?.name,
        email: parsedData?.email,
        address: parsedData?.address,
      });
    }
  }, []);

  useEffect(() => {
    const productDetail = localStorage.getItem("productdetail");
    if (productDetail) {
      const parsedData = JSON.parse(productDetail);
      setformdata({
        ...formdata,
        price: parsedData?.price,
        productdescribtion: parsedData?.productdescribtion,
        productname: parsedData?.productname,
        producturl: parsedData?.producturl,
        productimages: parsedData?.productimages,
      });
    }
  }, []);

  const steps = [
    {
      title: "Transaction Type",
      content: "Transaction Type",
    },
    {
      title: "Product Details",
      content: "Product Details",
    },
    {
      title: "Share Link",
      content: "Share Link",
    },
  ];

  const [current, setCurrent] = useState<number>(0);
  const next = () => {
    if (selectedRole === "seller") {
      navigate("/request_join_transaction");
    } else {
      setCurrent(current + 1);
    }

  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Header />

      <Layout
        style={{
          flex: 1,
          overflowX: "hidden",
          backgroundColor: "#F5F5F5",
        }}
      >
        {/* Scrollable Content */}
        <Content style={{ overflowY: "auto", padding: "15px", flexGrow: 1 }}>
          <Steps
            direction="horizontal"
            className="ant-steps-item-process"
            current={current}
            items={items}
            style={{
              marginBottom: "20px",
              display: "flex",
              overflowX: "auto",  // Add horizontal scroll if needed
              width: "100%",
              fontFamily: "Inter",
              fontWeight: 400,
            }}
            size={screens.xs ? "small" : "default"}
            responsive={false}    // This prevents responsive behavior
          />

          {current === 0 &&
            (deviceType === "desktop" ? (
              <motion.div
                initial={{ opacity: 0, y: 15 }}
                animate={{ opacity: deviceType === "desktop" ? 1 : 0, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <TransactionType
                  advanceSelected={selectedPaymentType === "advance"}
                  fullSelected={selectedPaymentType === "full"}
                  paymentAdvanceOnChange={() => setSelectedPaymentType("advance")}
                  paymentFullOnChange={() => setSelectedPaymentType("full")}
                  onClick={() => {
                    next();
                  }}
                  disabled={!selectedRole || !selectedPaymentType}
                  sellerselected={selectedRole === "seller"}
                  buyerselected={selectedRole === "buyer"}
                  sellerOnChange={() => setSelectedRole("seller")}
                  buyerOnChange={() => setSelectedRole("buyer")}
                />
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0, y: 15 }}
                animate={{ opacity: deviceType === "mobile" ? 1 : 0, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <TransactionTypeMobile
                  advanceSelected={selectedPaymentType === "advance"}
                  fullSelected={selectedPaymentType === "full"}
                  paymentAdvanceOnChange={() => setSelectedPaymentType("advance")}
                  paymentFullOnChange={() => setSelectedPaymentType("full")}
                  onClick={() => {
                    next();
                  }}
                  disabled={!selectedPaymentType || !selectedRole}
                  sellerselected={selectedRole === "seller"}
                  buyerselected={selectedRole === "buyer"}
                  sellerOnChange={() => setSelectedRole("seller")}
                  buyerOnChange={() => setSelectedRole("buyer")}
                />
              </motion.div>
            ))}

          {current === 1 &&
            (deviceType === "desktop" ? (
              <motion.div
                initial={{ opacity: 0, y: 15 }}
                animate={{ opacity: deviceType === "desktop" ? 1 : 0, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <ProductDetail
                  onClick={next}
                  onbackClick={prev}
                  formdata={formdata}
                  setformdata={setformdata}
                />
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0, y: 15 }}
                animate={{ opacity: deviceType === "mobile" ? 1 : 0, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <ProductDetailMobile
                  onClick={next}
                  onbackClick={prev}
                  formdata={formdata}
                  setformdata={setformdata}
                />
              </motion.div>
            ))}

          {current === 2 &&
            (deviceType === "desktop" ? (
              <motion.div
                initial={{ opacity: 0, y: 15 }}
                animate={{ opacity: deviceType === "desktop" ? 1 : 0, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <Verification
                  onbackClick={prev}
                  verificationformdata={verificationFormData}
                  setverificationformdata={setVerificationFormData}
                />
              </motion.div>
            ) : (
              <motion.div
                initial={{ opacity: 0, y: 15 }}
                animate={{ opacity: deviceType === "mobile" ? 1 : 0, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <VerificationMobile
                  onbackClick={prev}
                  verificationformdata={verificationFormData}
                  setverificationformdata={setVerificationFormData}
                />
              </motion.div>
            ))}
        </Content>
      </Layout>
    </div>
  );
};

export default CreateTransaction;
