import React, { FC } from "react";
import { TextArea } from "antd-mobile";

interface DescriptionInputMobileProps {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
}

const MultilinesInputMobile: FC<DescriptionInputMobileProps> = ({
  value,
  onChange,
  placeholder,
}) => {
  return (
    <div
      style={{
        position: "relative",
        borderRadius: "8px",
        border: "1px solid #cccccc",
        padding: "10px 10px",
        backgroundColor: "#fff",
        marginBottom: "5px",
      }}
    >
      <TextArea
        className="custom-input-multiline"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e)}
        style={{
          fontSize: "12px",
          lineHeight: "1.5",
          minHeight: "80px",
          border: "none",
          outline: "none",
          padding: "0",
          resize: "none",
          fontFamily: "Inter",
          fontWeight: 400,
        }}
      />
    </div>
  );
};

export default MultilinesInputMobile;
