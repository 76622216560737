import React, { useState } from 'react'
import Header from '../../Components/Global/Header'
import { Layout } from 'antd'
import { Content } from 'antd/es/layout/layout'
import Responsive from '../../Hooks/Responsive'
import TransactionFormMobile from '../../Components/SellerStepComponents/TransactionForm/TransactionFormMobile'
import { useNavigate } from 'react-router-dom'
import { motion } from "framer-motion";

interface VerificationFormData {
    mobilenumber?: string;
    otp?: string;
    name?: string;
    address?: string;
}

const TransactionForm: React.FC = () => {
    const deviceType = Responsive();
    const navigate = useNavigate();

    const [verificationFormData, setVerificationFormData] =
        useState<VerificationFormData>({
            mobilenumber: "",
            otp: "",
            name: "",
            address: "",
        });

    return (
        <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <Header />

            <Layout
                style={{
                    flex: 1,
                    overflowX: "hidden",
                    backgroundColor: "#F5F5F5",
                }}
            >
                {/* Scrollable Content */}
                <Content style={{ overflowY: "auto", padding: '15px', flexGrow: 1 }}>
                    {deviceType === "desktop" ? (
                        <TransactionFormMobile
                            onbackClick={() => { navigate(-1) }}
                            verificationformdata={verificationFormData}
                            setverificationformdata={setVerificationFormData}
                        />
                    ) : (
                        <motion.div
                            initial={{ opacity: 0, y: 15 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8 }}
                        >
                            <TransactionFormMobile
                                onbackClick={() => { navigate(-1) }}
                                verificationformdata={verificationFormData}
                                setverificationformdata={setVerificationFormData}
                            />
                        </motion.div>
                    )}
                </Content>
            </Layout>
        </div >
    )
}

export default TransactionForm