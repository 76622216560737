import React from "react";
import { ShoppingCartOutlined, ShopOutlined, CreditCardOutlined } from "@ant-design/icons";
import SelectableButton from "../../Global/SelectableButton/Mobile";
import CustomButton from "../../Global/CustomButton/Mobile";
import { Card } from "antd-mobile";
import color from "../../../Utils/color";

interface TransactionTypeProps {
  sellerselected: boolean;
  buyerselected: boolean;
  sellerOnChange: () => void;
  buyerOnChange: () => void;
  onClick: () => void;
  disabled: boolean;
  advanceSelected: boolean;
  fullSelected: boolean;
  paymentAdvanceOnChange: () => void;
  paymentFullOnChange: () => void;
}

const TransactionTypeMobile: React.FC<TransactionTypeProps> = ({
  buyerselected,
  buyerOnChange,
  sellerselected,
  sellerOnChange,
  onClick,
  disabled,
  advanceSelected,
  fullSelected,
  paymentAdvanceOnChange,
  paymentFullOnChange,
}) => {
  console.log("🚀 ~ buyerselected:", buyerselected)
  return (
    <Card
      style={{
        borderRadius: "18px",
        boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
      }}
    >
      <h2 style={{
        textAlign: "center",
        fontFamily: "Inter",
        fontWeight: 700,
        marginBottom: "40px",
      }}>Create a New Transaction</h2>
      <p style={{
        fontFamily: "Inter",
        fontWeight: 700, marginBottom: "10px", fontSize: "12px"
      }}>
        I am a <span style={{ color: "red" }}>*</span>
      </p>
      <SelectableButton
        icon={
          <ShoppingCartOutlined
            style={{
              fontSize: "20px",
              color: buyerselected ? color.primary : "#e8e8e8",
            }}
          />
        }
        label="Buyer"
        onChange={buyerOnChange}
        selected={buyerselected}
        radioclick={buyerOnChange}
      />

      <SelectableButton
        icon={
          <ShopOutlined
            style={{
              fontSize: "20px",
              color: sellerselected ? color.primary : "#e8e8e8",
            }}
          />
        }
        label="Seller"
        onChange={sellerOnChange}
        selected={sellerselected}
        radioclick={sellerOnChange}
      />

      <p style={{ marginBottom: "10px", fontFamily: "Inter", fontWeight: 700, fontSize: "12px", marginTop: "40px" }}>
        Payment Type
      </p>
      <SelectableButton
        icon={
          <CreditCardOutlined
            style={{
              fontSize: "20px",
              color: advanceSelected ? color.primary : "#e8e8e8",
            }}
          />
        }
        label="Advance Payment"
        onChange={paymentAdvanceOnChange}
        selected={advanceSelected}
        radioclick={paymentAdvanceOnChange}
      />

      <SelectableButton
        icon={
          <CreditCardOutlined
            style={{
              fontSize: "20px",
              color: fullSelected ? color.primary : "#e8e8e8",
            }}
          />
        }
        label="Full Payment"
        onChange={paymentFullOnChange}
        selected={fullSelected}
        radioclick={paymentFullOnChange}
      />


      <div style={{ marginTop: "20%" }}>
        <CustomButton
          label="Next"
          onClick={onClick}
          disabled={disabled}
        />
      </div>
    </Card>
  );
};

export default TransactionTypeMobile;
