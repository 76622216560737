import React, { useEffect } from "react";
import TransactionLinkDesktop from "../../Components/StepsScreenComponents/TransactionLink/Desktop";
import TransactionLinkMobile from "../../Components/StepsScreenComponents/TransactionLink/Mobile";
import Responsive from "../../Hooks/Responsive";
import Header from "../../Components/Global/Header";
import BottomBar from "../../Components/Global/BottomBar";
import { useNavigate } from "react-router-dom";
import { Layout } from "antd";
import { Content } from "antd/es/layout/layout";


const TransactionLinkScreen: React.FC = () => {
    const deviceType = Responsive();
    const navigate = useNavigate();

    useEffect(() => {
        // Handle back button press
        const handleBackButton = (e: PopStateEvent) => {
            // Clear localStorage
            localStorage.removeItem('verification');
            localStorage.removeItem('productdetail');
            // You can add other items to clear if needed

            // Navigate to home or any other route
            navigate('/', { replace: true });
        };

        window.addEventListener('popstate', handleBackButton);

        // Cleanup
        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, [navigate]);
    return (
        <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <Header />

            <Layout
                style={{
                    flex: 1,
                    overflowX: "hidden",
                    backgroundColor: "#F5F5F5",
                }}
            >
                {/* Scrollable Content */}
                <Content style={{ overflowY: "auto", padding: "15px", flexGrow: 1 }}>
                    {deviceType === "desktop" ? <TransactionLinkDesktop /> : <TransactionLinkMobile />}
                </Content>
                <BottomBar
                    onStartTransactionClick={() => { }}
                    onMyTransactionsClick={() => { }}
                    onMyListingsClick={() => { }}
                    onProfileClick={() => { }}
                    activeTab="startTransaction"
                />
            </Layout>
        </div>
    );
};

export default TransactionLinkScreen;
