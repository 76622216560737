import { Radio } from "antd-mobile";
import React from "react";

interface SelectableButtonProps {
  icon: React.ReactNode;
  label: string;
  onChange: () => void;
  selected: boolean;
  radioclick: (e: React.MouseEvent) => void;
}

const SelectableButtonMobile: React.FC<SelectableButtonProps> = ({
  icon,
  label,
  onChange,
  selected,
  radioclick,
}) => {
  return (
    <div
      onClick={onChange}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "50px",
        padding: "0 16px",
        border: "1px solid #e8e8e8",
        borderRadius: "8px",
        cursor: "pointer",
        transition: "all 0.3s ease",
        marginBottom: 15,
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {icon}
        <span
          style={{ marginLeft: "10px", fontSize: "12px", fontWeight: "400", fontFamily: "Inter" }}
        >
          {label}
        </span>
      </div>

      <Radio className="custom-radio" checked={selected} onClick={radioclick} />
    </div>
  );
};

export default SelectableButtonMobile;
