import React, { useState } from "react";
import NotificationBoxMobile from "../NotificationBox/Mobile";
import { InformationCircleOutline, LockOutline, CameraOutline } from "antd-mobile-icons";
import { WarningOutlined, FieldTimeOutlined, CheckCircleOutlined } from "@ant-design/icons";
import ProductCardMobile from "../ProductCard/Mobile";

import { Card, Switch } from "antd-mobile";
import CustomInputMobile from "../CustomInput/Mobile";
import { motion } from "framer-motion";
import CustomButtonMobile from "../CustomButton/Mobile";
import CustomDropdownMobile from "../CustomDropDown/Mobile";
import CameraCustomMobile from "../CameraCustom/Mobile";

interface StepIndicatorProps {
    currentStep: number;
    completedSteps: number[];
    steps: any;
    productData: any;
    formdata: any;
    setFormData: any;
    handleOpenFrontCamera: any;
    handleOpenBackCamera: any;
    handleSubmit: any;
    data: any;
}

const SellerStepIndicatorMobile: React.FC<StepIndicatorProps> = ({
    currentStep,
    completedSteps,
    steps,
    productData,
    formdata,
    setFormData,
    handleOpenFrontCamera,
    handleOpenBackCamera,
    handleSubmit,
    data
}) => {

    return (
        <div>
            <h2 style={{ fontSize: "17px", fontWeight: "700", fontFamily: "Inter", color: "#000000", textAlign: "center", margin: "0 10px" }}>
                Thank you for joining the transaction
            </h2>

            <NotificationBoxMobile
                icon={<InformationCircleOutline style={{ fontSize: "15px", marginRight: '7px' }} />}
                title="We have notified the buyer to make the payment to the TrustPay hold and once the payment is made you will be notified to ship the product."
            />

            <div style={styles.container}>
                {steps.map((step: any, index: any) => {
                    const isCompleted = completedSteps.includes(step.id);
                    const isActive = step.id === currentStep;

                    return (
                        <div key={step.id} style={styles.stepContainer2}>
                            <div style={styles.stepInnerContainer}>
                                <div style={styles.stepContentContainer}>
                                    <div
                                        style={{
                                            ...styles.circle,
                                            ...(isCompleted ? styles.completedCircle : {}),
                                            ...(isActive ? styles.activeCircle : {}),
                                        }}
                                    >
                                        <span style={{ ...styles.checkMark, backgroundColor: isActive ? "#FF9800" : isCompleted ? "#4CAF50" : "#AEAEB2" }}></span>
                                    </div>
                                    {index < steps.length - 1 && <div style={styles.line} />}
                                </div>
                                <div style={styles.labelContainer}>
                                    <span
                                        style={{
                                            ...styles.label,
                                            ...(isActive ? styles.activeLabel : {}),
                                            ...(isCompleted ? styles.completedLabel : {}),
                                        }}
                                    >
                                        {step.label}
                                    </span>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

            <ProductCardMobile
                title={productData.title}
                price={productData.price}
                description={productData.description}
                images={productData.images}
            />


            {data.status === 'pending' || data.status === 'success' ?
                <div style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    padding: '10px 10px',
                    borderRadius: '5px',
                    marginTop: '13px'
                }}>
                    {data.status === 'success' ?
                        <CheckCircleOutlined style={{ color: "#34C759", fontSize: "15px", marginRight: '7px', marginTop: '1px' }} />
                        :
                        <FieldTimeOutlined style={{ color: "#FF9500", fontSize: "15px", marginRight: '7px', marginTop: '1px' }} />}
                    <span style={{
                        flex: 1,
                        color: '#000000',
                        fontSize: '14px',
                        fontFamily: 'Inter',
                        fontWeight: '700',
                        lineHeight: '18px',
                    }}>
                        {data.status === 'success' ? "Verification completed." : " Verification information submitted. Pending verification."}
                    </span>
                </div>
                :

                <>
                    {!formdata.actionRequired && <NotificationBoxMobile
                        icon={<WarningOutlined style={{ fontSize: "12px", marginRight: '7px' }} />}
                        title="Action Required"
                        backgroundColor="#FEE6D3"
                        borderColor="#FF9500"
                        style={{ width: "110px" }}
                        styletitle={{ lineHeight: '14px', fontSize: '10px' }}
                        padding="2px 5px"
                    />}

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <p style={{ fontSize: '11px', fontWeight: '500', fontFamily: 'Inter', color: '#000000', marginRight: '10px' }}>Complete your bank details and verify your ID to receive payment as soon as the transaction completes.</p>
                        <Switch
                            checked={formdata.actionRequired}
                            onChange={() => { setFormData({ ...formdata, actionRequired: !formdata.actionRequired }) }}
                            style={{
                                '--checked-color': '#34C759',
                                '--border-width': '1px',
                                '--height': '20px',
                                '--width': '30px',
                            }}
                        />
                    </div>

                    {formdata.actionRequired &&
                        <motion.div
                            initial={{ opacity: 0, y: 15 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8 }}
                        >
                            <Card
                                style={{
                                    borderRadius: "18px",
                                    boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                                    position: 'relative'
                                }}
                            >
                                <p style={{ fontSize: "9px", fontWeight: "400", fontFamily: "Inter", color: "#34C759", marginBottom: '0px' }}>Bank Details</p>

                                <h4 style={{ marginBottom: "3px", fontSize: "12px", fontWeight: "700", fontFamily: "Inter", marginTop: "10px" }}>Name of the account holder</h4>
                                <CustomInputMobile
                                    placeholder="Name of the account holder"
                                    value={formdata.name || ""}
                                    onChange={(e: string) => {
                                        setFormData({
                                            ...formdata,
                                            name: e,
                                        });
                                    }}
                                />
                                <h4 style={{ marginBottom: "3px", fontSize: "12px", fontWeight: "700", fontFamily: "Inter", marginTop: "10px" }}>Account number</h4>
                                <CustomInputMobile
                                    type="number"
                                    placeholder="Account number"
                                    value={formdata.accountNumber || ""}
                                    onChange={(e: string) => {
                                        setFormData({
                                            ...formdata,
                                            accountNumber: e,
                                        });
                                    }}
                                />
                                <h4 style={{ marginBottom: "3px", fontSize: "12px", fontWeight: "700", fontFamily: "Inter", marginTop: "10px" }}>Name of the bank</h4>
                                <CustomInputMobile
                                    placeholder="Name of the bank"
                                    value={formdata.bankName || ""}
                                    onChange={(e: string) => {
                                        setFormData({
                                            ...formdata,
                                            bankName: e,
                                        });
                                    }}
                                />
                                <h4 style={{ marginBottom: "3px", fontSize: "12px", fontWeight: "700", fontFamily: "Inter", marginTop: "10px" }}>Branch</h4>
                                <CustomInputMobile
                                    placeholder="Branch"
                                    value={formdata.branchName || ""}
                                    onChange={(e: string) => {
                                        setFormData({
                                            ...formdata,
                                            branchName: e,
                                        });
                                    }}
                                />


                                {/* indentity verification */}
                                <p style={{ fontSize: "9px", fontWeight: "400", fontFamily: "Inter", color: "#34C759", marginBottom: '0px', marginTop: '30px' }}>
                                    Identity Verification
                                </p>

                                <p style={{ fontSize: "12px", fontWeight: "400", fontFamily: "Inter", color: "#000000", marginBottom: '0px', marginRight: '20px' }}>
                                    Please select your identity type for verification.
                                </p>

                                <CustomDropdownMobile
                                    options={["National Identity Card", "Passport", "Driving License"]}
                                    value={formdata.identityType}
                                    onChange={(value) => {
                                        setFormData({
                                            ...formdata,
                                            identityType: value,
                                        });
                                    }}
                                    placeholder="-Select-"
                                />

                                {/* Upload photo */}
                                <h4 style={{ marginBottom: "3px", fontSize: "12px", fontWeight: "700", fontFamily: "Inter", marginTop: "10px" }}>Upload a photo of your ID</h4>

                                {/* Replace the previous upload boxes with these pressable ones */}
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    gap: '5px',
                                    marginBottom: '15px',
                                    marginTop: '5px'
                                }}>
                                    <div style={{ flex: 1 }}>
                                        <p style={{ fontSize: "10px", fontWeight: "400", fontFamily: "Inter", color: "#000000", marginBottom: '3px' }}>
                                            Front
                                        </p>
                                        <button
                                            style={{
                                                width: '100%',
                                                background: 'none',
                                                border: 'none',
                                                cursor: 'pointer',
                                                padding: '0px',
                                                overflow: 'hidden'
                                            }}
                                            onClick={handleOpenFrontCamera}
                                        >
                                            {formdata.frontImage ? <img
                                                src={formdata.frontImage}
                                                alt="Front"
                                                style={{
                                                    width: '100%', height: '90px', objectFit: 'cover', border: '1px dashed #AEAEB2',
                                                    borderRadius: '5px',
                                                }}
                                            /> :
                                                <div style={{
                                                    border: '1px dashed #AEAEB2',
                                                    borderRadius: '5px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    backgroundColor: '#F5F5F5',
                                                    transition: 'all 0.2s ease',
                                                    cursor: 'pointer',
                                                    height: '90px'
                                                }}>
                                                    <CameraOutline style={{ fontSize: '15px', color: '#8E8E93' }} />
                                                    <span style={{ fontSize: '9px', color: '#8E8E93', fontWeight: '400', fontFamily: "Inter" }}>ID Photo</span>
                                                </div>
                                            }

                                        </button>
                                    </div>

                                    <div style={{ flex: 1 }}>
                                        <p style={{ fontSize: "10px", fontWeight: "400", fontFamily: "Inter", color: "#000000", marginBottom: '3px' }}>
                                            Back
                                        </p>
                                        <button
                                            style={{
                                                width: '100%',
                                                background: 'none',
                                                border: 'none',
                                                padding: 0,
                                                cursor: 'pointer',
                                                overflow: 'hidden'
                                            }}
                                            onClick={handleOpenBackCamera}
                                        >
                                            {formdata.backImage ? <img
                                                src={formdata.backImage}
                                                alt="Back"
                                                style={{
                                                    width: '100%', height: '90px', objectFit: 'cover', border: '1px dashed #AEAEB2',
                                                    borderRadius: '5px',
                                                }}
                                            /> :
                                                <div style={{
                                                    border: '1px dashed #AEAEB2',
                                                    borderRadius: '5px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    backgroundColor: '#F5F5F5',
                                                    transition: 'all 0.2s ease',
                                                    cursor: 'pointer',
                                                    height: '90px'
                                                }}>
                                                    <CameraOutline style={{ fontSize: '15px', color: '#8E8E93' }} />
                                                    <span style={{ fontSize: '9px', color: '#8E8E93', fontWeight: '400', fontFamily: "Inter" }}>ID Photo</span>
                                                </div>
                                            }
                                        </button>
                                    </div>
                                </div>

                                <NotificationBoxMobile
                                    icon={<LockOutline style={{ fontSize: "15px", marginRight: '7px' }} />}
                                    title="Your information will be encrypted, stored securely and only ever used to verify your identity."
                                    borderColor="#34C759"
                                    backgroundColor="#E6F9E8"
                                />

                                <CustomButtonMobile
                                    disabled={!formdata.name || !formdata.accountNumber || !formdata.bankName || !formdata.branchName || !formdata.frontImage || !formdata.backImage}
                                    fill="solid"
                                    label="Submit"
                                    onClick={handleSubmit}
                                    style={{
                                        fontSize: "12px",
                                        marginTop: '50px'
                                    }}
                                />
                            </Card>
                        </motion.div>
                    }
                </>
            }

            <span style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: "12px", marginTop: "30px" }}>
                <a href="#" style={{ color: "#000000", textDecorationLine: "underline", fontWeight: "400", fontFamily: "Inter", }}>
                    Need Help? Contact Support
                </a>
            </span>
        </div >

    );
};

const styles = {
    container: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        padding: "20px 0",
        width: "100%",
        marginTop: "20px",
    },
    stepContainer2: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        height: "100px",
    },
    stepInnerContainer: {
        display: "flex",
        flexDirection: "column" as const,
        alignItems: "center",
        // gap: "12px",
    },
    stepContentContainer: {
        display: "flex",
        flexDirection: "row" as const,
        alignItems: "center",
        position: "relative" as const,

    },
    circle: {
        width: "17px",
        height: "17px",
        borderRadius: "50%",
        border: "2px solid #AEAEB2",
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1,
    },
    activeCircle: {
        border: "2px solid #FF9800",
    },
    completedCircle: {
        border: "2px solid #4CAF50",
        backgroundColor: "#4CAF50",
    },
    checkMark: {
        // color: "#fff",
        // fontSize: "14px",
        height: "10px",
        width: "10px",
        borderRadius: "50%",
        backgroundColor: "gray",
        // marginLeft: "0.7px",
    },
    labelContainer: {
        marginTop: "4px",
        textAlign: "center" as const,
    },
    label: {
        fontSize: "10px",
        color: "#8E8E93",
        maxWidth: "50px",
        display: "block",
        fontWeight: "400",
        fontFamily: "Inter",
    },
    activeLabel: {
        color: "#FF9800",
        fontWeight: "500",
    },
    completedLabel: {
        color: "#4CAF50",
        fontWeight: "500",
    },
    line: {
        width: "31vw",
        height: "1px",
        margin: "0 4px 0px 4px",
        backgroundColor: "#AEAEB2",
        position: "absolute" as const,
        left: "100%",
        top: "50%",
        transform: "translateY(-50%)",
    },
};

export default SellerStepIndicatorMobile;