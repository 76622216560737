import React, { useState } from 'react'
import Header from '../../Components/Global/Header'
import { Layout } from 'antd'
import { Content } from 'antd/es/layout/layout'
import Responsive from '../../Hooks/Responsive'
import RequestTransactionMobile from '../../Components/SellerStepComponents/RequestTransaction/RequestTransactionMobile'
import { useNavigate } from 'react-router-dom'

const SellerStepScreen: React.FC = () => {
    const deviceType = Responsive();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        seller_invite_terms: false,
    });

    const handleTermsChange = (checked: boolean) => {
        setFormData({ ...formData, seller_invite_terms: checked });
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <Header />

            <Layout
                style={{
                    flex: 1,
                    overflowX: "hidden",
                    backgroundColor: "#F5F5F5",
                }}
            >
                <Content style={{ overflowY: "auto", padding: "15px", flexGrow: 1 }}>
                    {deviceType === "desktop" ? (
                        <RequestTransactionMobile handleTermsChange={handleTermsChange} setFormData={setFormData} formData={formData} onclick={() => { navigate('/request_join_transaction/join_transaction') }} />
                    ) : (
                        <RequestTransactionMobile handleTermsChange={handleTermsChange} setFormData={setFormData} formData={formData} onclick={() => { navigate('/request_join_transaction/join_transaction') }} />
                    )}
                </Content>
            </Layout>
        </div >
    )
}

export default SellerStepScreen