import React from "react";
import { Button } from "antd";

interface CustomButtonProps {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  type?: "default" | "outline";
  style?: React.CSSProperties;
  className?: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  label,
  onClick,
  disabled = false,
  type = "default",
  style,
  className,
}) => {
  return (
    <Button
      className={className}
      type="default"
      block
      onClick={onClick}
      onMouseDown={(e) => e.currentTarget.style.transform = "scale(0.95)"} // Scale down on press
      onMouseUp={(e) => e.currentTarget.style.transform = "scale(1)"} // Reset scale on release
      onMouseLeave={(e) => e.currentTarget.style.transform = "scale(1)"} // Reset scale if mouse leaves
      disabled={disabled}
      style={{
        width: "100%",
        height: "35px",
        marginTop: "16px",
        borderRadius: "100px",
        transition: "transform 0.1s ease-in-out",
        background:
          type === "outline" ? "transparent" : disabled ? "#E3EADE" : "#9FE870",
        color:
          type === "outline" ? "#163300" : disabled ? "#99A093" : "#163300",
        border: `1px solid ${type === "outline" ? "#163300" : disabled ? "#E3EADE" : "#9FE870"
          }`,
        cursor: disabled ? "not-allowed" : "pointer",
        fontFamily: "Inter",
        fontWeight: 700,
        ...style,
      }}
    >
      {label}
    </Button>
  );
};

export default CustomButton;
